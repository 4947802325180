import axios from 'axios';

import { useUserStore } from '../stores/userStore';

type OffersResponse = {
  data?: Array<{
    id: number;
    anchor: string;
    things_to_know: Array<string>;
    requirements: string;
    description: string;
    disclaimer: string;
    confirmation_time: string;
    pending_time: number;
    is_game: boolean;
    app_metadata: null | {
      app_id: string;
      categories: Array<string>;
      screenshot_urls: Array<string>;
      video_urls: Array<string>;
    };
    web2mobile: boolean;
    web2mobile_devices: null | Array<string>;
    click_url: string;
    support_url: string;
    icon_url: string;
    categories: Array<string>;
    sticky: boolean;
    events: Array<Event>;
    total_points: number;
    total_promotional_points: null | number;
    rank: number;
  }>;
  message?: string;
};

export default {
  async get() {
    const userStore = useUserStore();

    return axios.get<OffersResponse>('https://offers.bitlabs.link/v1/user-based-api/offers', {
      params: {
        user_id: userStore.user.userId,
        wall_code: userStore.offerwallCode,
        s2: 'bitburst:user-based',
        s3: userStore.user.uid,
        // s4
        in_app: false,
        bb_ow: true,
      },
    });
  },
};
