<template>
  <transition
    appear
    :enter-from-class="transitionClasses"
    :leave-to-class="transitionClasses"
    enter-active-class="duration-300 ease-out"
    leave-active-class="duration-700 ease-out"
  >
    <div
      v-if="visible"
      class="fixed z-50 hidden w-[25rem] flex-col items-center justify-between shadow-lg sm:flex"
      :class="{
        'bottom-10 left-16': position === 'bottom-left',
        'bottom-10 right-16': position === 'bottom-right',
        'left-16 top-16': position === 'top-left',
        'right-16 top-16': position === 'top-right',
      }"
    >
      <div
        class="rounded-8 relative box-border flex w-full items-center justify-between overflow-hidden border border-gray-100 bg-white p-16 dark:border-gray-700 dark:bg-black"
      >
        <div class="flex items-center text-red-500">
          <font-awesome-icon :icon="faFire" size="lg" class="mr-16" />
          <div class="flex flex-col justify-center">
            <span v-t="'widgetHotSurveyNotificationText'" class="text-17 font-semibold" />
            <div class="text-15 flex items-center justify-center font-semibold">
              <currency-amount :amount="bestSurvey.value" prefix="+ " />
            </div>
          </div>
        </div>
        <div class="flex items-center justify-end">
          <bb-button
            class="bg-branding-light-500 dark:bg-branding-dark-500 text-branding-contrast-light-500 dark:text-branding-contrast-dark-500 mr-10 hover:brightness-110"
            @click="startSurvey"
            >{{ $t('widgetHotSurveyNotificationStartButton') }}</bb-button
          >
        </div>
        <bb-progress
          :value="percent"
          class="!absolute bottom-0 left-0 w-full !rounded-none !bg-white !p-[0px] dark:!bg-black"
          custom="progress-red"
        />
      </div>
      <div
        class="absolute -right-8 -top-8 flex h-20 w-20 cursor-pointer items-center justify-center rounded-full bg-gray-50 shadow-sm hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
        @click="closeNotification"
      >
        <font-awesome-icon size="lg" :icon="faTimes" />
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { BbButton, BbProgress } from '@bitburst-gmbh/bitburst-ui';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faFire } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import { emitter } from '../emitter';
import { storage } from '../storage';
import { useSurveysStore } from '../stores/surveysStore';
import type { WidgetPosition } from '../types/widget';

import CurrencyAmount from './CurrencyAmount.vue';

const props = defineProps<{
  position: WidgetPosition;
}>();

const { bestSurvey, surveys } = storeToRefs(useSurveysStore());

// Behaviour
const closeNotification = () => {
  clearInterval(timer.value);
  showBestSurvey.value = false;
};
const startSurvey = () => {
  closeNotification();
  emitter.emit('open-offerwall', {
    position: 'last',
    link: bestSurvey.value.link,
  });
};

// Visibility
const showBestSurvey = ref(false);
const visible = computed<boolean>(() => {
  if (!bestSurvey.value.link) return false;
  if (uniqueIdIncluded()) return false;
  if (showBestSurvey.value) resetProgress();
  return showBestSurvey.value;
});
const timer = ref(-1);
watch([visible], ([newVisible]) => {
  if (!newVisible) return;

  clearInterval(timer.value);
  setTimeout(() => {
    timer.value = window.setInterval(() => {
      if (percent.value === 0) {
        closeNotification();
        return;
      }
      percent.value = percent.value - 0.5;
    }, 300);
  }, 2000);
});

// Progress
const percent = ref(100);
const resetProgress = () => (percent.value = 100);

// Transition
const positionX = computed(() => (props.position.includes('right') ? 'right' : 'left'));
const transitionClasses = computed(() => `opacity-0 ${positionX.value === 'left' ? '-' : ''}translate-x-20`);

// Already shown surveys
const alreadyShownSurveyIDs = ref<Array<string>>([]);
const uniqueIdIncluded = () => {
  if (alreadyShownSurveyIDs.value.includes(bestSurvey.value.uniqueId)) {
    return true;
  }

  alreadyShownSurveyIDs.value.push(bestSurvey.value.uniqueId);
  void storage.local.setItem('sessionIDs', alreadyShownSurveyIDs.value);

  showBestSurvey.value = true;
  return false;
};
const isToday = (dateStamp: string): boolean => {
  const date = new Date(dateStamp);
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};
const handleAlreadyShownSurveys = async () => {
  let ids: Array<string> = [];

  const exists = await storage.local.hasItem('sessionDate');
  const date = await storage.local.getItem('sessionDate').catch(() => void 0);

  if (exists && isToday(date ?? '')) {
    ids = (await storage.local.getItem('sessionIDs')) ?? [];
  } else {
    await storage.local.setItem('sessionDate', Date.now().toString());
  }

  if (!surveys.value[0]) return;
  alreadyShownSurveyIDs.value = [...new Set([...ids, surveys.value[0].uniqueId])];
};
void handleAlreadyShownSurveys();
</script>

<style lang="scss" scoped>
.progress-red {
  --bb-progress__bar: theme(colors.red.500);
  --bb-progress__bar--dark: theme(colors.red.500);
}
</style>
