import { defineStore } from 'pinia';
import { ref } from 'vue';

import { Api } from '../api';
import { User } from '../types/user';

const useUserStore = defineStore('user', () => {
  const user = ref<User>({ uid: '', userId: -1, username: '' });
  const offerwallCode = ref<string>('');

  const fetchUser = async () => {
    user.value = await Api.Bitlabs.v1.getUser().then(data => {
      const user = data.data.data;
      offerwallCode.value = user?.offerwall_code ?? '';
      return {
        uid: user?.uid ?? '',
        userId: user?.user_id ?? -1,
        username: user?.username ?? '',
      };
    });
  };

  return { offerwallCode, user, fetchUser };
});

export { useUserStore };
