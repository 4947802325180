<template>
  <div
    class="bb-progress rounded-4 relative h-8 bg-white dark:bg-black"
    :class="{
      [`${custom} !bg-[--bb-progress__bg] dark:!bg-[--bb-progress__bg--dark]`]: !!custom,
    }"
    role="progressbar"
    :aria-valuenow="value"
    :aria-valuemin="min"
    :aria-valuemax="max"
  >
    <div
      class="rounded-4 box-border h-full origin-left bg-blue-500 transition-transform"
      :class="{
        '!bg-[--bb-progress__bar] dark:!bg-[--bb-progress__bar--dark]': !!custom,
      }"
      :style="{ transform: `scaleX(${percentage}%)` }"
    />
    <div class="absolute inset-0">
      <slot v-bind="{ percentage, value, min, max }" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const {
  value,
  min = 0,
  max = 100,

  custom = '',
} = defineProps<{
  value: number;

  min?: number;
  max?: number;

  custom?: string;
}>();

const percentage = computed(() => {
  return Math.max(Math.min(((value - min) / (max - min)) * 100, 100), 0);
});
</script>
