import { storeToRefs } from 'pinia';
import { createApp, h, reactive } from 'vue';

import './scss/main.scss';
import { initApi } from './api';
import ContainerSdk from './components/ContainerSdk.vue';
import ContainerWidget from './components/ContainerWidget.vue';
import { i18n, loadLanguageAsync } from './i18n';
import { interfaceSdk, interfaceWidget } from './interface';
import { pinia } from './stores';
import { useOffersStore } from './stores/offersStore';
import { defaultCallback, useOptionsStore } from './stores/optionsStore';
import { useSettingsStore } from './stores/settingsStore';
import { useSurveysStore } from './stores/surveysStore';
import { useUserStore } from './stores/userStore';
import type { InitSdk, ShowWidget, WidgetProps } from './types/widget';

let isInitialized = false;

const appSdk = createApp(ContainerSdk);
appSdk.use(pinia);
appSdk.use(i18n);

const initSdk: InitSdk = async (token, uuid, options = {}) => {
  if (isInitialized) return Promise.resolve(interfaceSdk());
  if (!token || !uuid) return Promise.reject();

  initApi(token, uuid);
  await loadLanguageAsync('en');

  const optionsStore = useOptionsStore();
  optionsStore.$patch({
    bestSurveyNotificationPosition: options.bestSurveyNotificationPosition ?? 'bottom-left',
    customFloatingWidgetMessage: options.customFloatingWidgetMessage ?? i18n.global.t('staticWidgetMessage').toString(),
    disableBestSurveyNotification: options.disableBestSurveyNotification ?? false,
    floatingWidgetPosition: options.floatingWidgetPosition ?? 'bottom-right',
    showFloatingWidget: options.showFloatingWidget ?? false,
    token: token,
    username: options.username ?? '',
    uuid: uuid,
  });

  const settingsStore = useSettingsStore();
  await settingsStore.fetchSettings();
  const userStore = useUserStore();
  await userStore.fetchUser();
  const surveysStore = useSurveysStore();
  void surveysStore.fetchSurveys();
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  setInterval(() => surveysStore.fetchSurveys(), 30000);
  const offersStore = useOffersStore();
  if (settingsStore.settings.strings.get('app.features.offers.enabled') === '1') void offersStore.fetchOffers();

  const offerwallWrapperID = 'bitlabs-sdk-offerwall';
  const offerwallWrapper = document.createElement('div');
  offerwallWrapper.id = offerwallWrapperID;
  document.body.appendChild(offerwallWrapper);

  appSdk.mount('#' + offerwallWrapperID);
  isInitialized = true;

  return Promise.resolve(interfaceSdk());
};
const showWidget: ShowWidget = (
  elementID,
  design = 'simple',
  position = 'bottom-right',
  callback = defaultCallback,
  options = {}
) => {
  if (!isInitialized) {
    // eslint-disable-next-line no-console
    console.warn(
      '[BitLabs SDK] Bitlabs SDK is not initialized. Make sure to call window.bitlabsSDK.init before showing the widget'
    );
    return;
  }

  if (!elementID || !document.querySelector(elementID)) {
    // eslint-disable-next-line no-console
    console.warn(`[BitLabs SDK] Element with selector: ${elementID} not found. ¯\\_(ツ)_/¯`);
    return;
  }

  const { callback: storeCallback } = storeToRefs(useOptionsStore());
  storeCallback.value = callback;

  const props: WidgetProps = reactive({ design, position, options });
  const appWidget = createApp({
    name: 'container-widget',

    render: () => h(ContainerWidget, props),
  });
  appWidget.use(pinia);
  appWidget.use(i18n);
  appWidget.mount(elementID);

  return interfaceWidget(props);
};

const documentWindow = window as Window & typeof globalThis & { bitlabsSDK: { init: InitSdk; showWidget: ShowWidget } };
documentWindow.bitlabsSDK = { init: initSdk, showWidget: showWidget };
