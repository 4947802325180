import { createStorage, prefixStorage } from 'unstorage';
import localStorageDriver from 'unstorage/drivers/localstorage';

type Local = {
  items: {
    sessionDate: string;
    sessionIDs: Array<string>;
  };
};

const local = createStorage<Local>({ driver: localStorageDriver({}) });
const storage = {
  local: prefixStorage(local, 'bitlabs-widgets'),
};

export { storage };
