import { defineStore } from 'pinia';
import { ref } from 'vue';

import { Api } from '../api';
import { Offer } from '../types/offer';

const useOffersStore = defineStore('offers', () => {
  const isFetching = ref<boolean>(false);
  const offers = ref<Array<Offer>>([]);

  const fetchOffers = async () => {
    isFetching.value = true;
    offers.value = await Api.Offers.get().then(data => {
      const offers = data.data.data;
      if (!offers) return [];

      return offers.map(offer => ({
        id: offer.id,
        title: offer.anchor,
        icon: offer.icon_url,
        cpa: offer.total_points,
        link: `/offers?offer-id=${offer.id}`,
      }));
    });
    isFetching.value = false;
  };

  return { isFetching, offers, fetchOffers };
});

export { useOffersStore };
